import { CustomRouteObject } from './types'
import { lazyLoad } from './utils'

// ----------- Pages -----------
const PortalInit = lazyLoad(
  () => import(/* webpackChunkName: 'customer-portal-init' */ '~/pages/auth/PortalInit'),
)

const PortalAuthInit = lazyLoad(
  () => import(/* webpackChunkName: 'customer-portal-auth-init' */ '~/pages/auth/PortalAuthInit'),
)

// ----------- Routes -----------
export const CUSTOMER_PORTAL_AUTH_ROUTE = '/customer-portal/login'
export const CUSTOMER_PORTAL_ROUTE = '/customer-portal/:token'

export const customerPortalRoutes: CustomRouteObject[] = [
  {
    path: CUSTOMER_PORTAL_ROUTE,
    element: <PortalInit />,
  },
  {
    path: CUSTOMER_PORTAL_AUTH_ROUTE,
    element: <PortalAuthInit />,
  },
]
